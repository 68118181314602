<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap py-8>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="con4">Your Name*</span>
                </v-flex>
                <v-flex xs12 py-2>
                  <v-text-field
                    class="tes"
                    v-model="contact.name"
                    :rules="nameRules"
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="con4">Mobile Number*</span>
                </v-flex>
                <v-flex xs12 py-2>
                  <v-text-field
                    class="tes"
                    :rules="phoneRules"
                    v-model="contact.mobile"
                    required
                    outlined
                    maxlength="10"
                    type="number"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="con4">Your Email*</span>
                </v-flex>
                <v-flex xs12 py-2>
                  <v-text-field
                    class="tes"
                    v-model="contact.email"
                    :rules="emailRules"
                    required
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 py-2>
              <span class="con4">Cover Letter (PDF is preferred)</span>
              &nbsp;
              <input
                v-show="true"
                accept="image/*,.pdf"
                id="file1"
                ref="files"
                type="file"
                @change="browseCover"
              />
              <!-- <v-icon @click="$refs.files.click()" color="black"
                  >mdi-upload</v-icon
                > -->
              <!-- <br />
              <br />
              <span
                style="padding-bottom: 6px; border-bottom: 2px solid grey"
                v-if="cvFile"
                >{{ cvFile.name }}
                <v-icon color="red" small @click="cvFile = null"
                  >mdi-close</v-icon
                ></span
              > -->
            </v-flex>

            <v-flex xs12 py-6>
              <span class="con4">Resume* (PDF is preferred)</span>
              &nbsp;
              <input
                v-show="true"
                accept="image/*,.pdf"
                id="file2"
                ref="files2"
                type="file"
                @change="browseImage"
              />
              <!-- <v-icon @click="$refs.files2.click()" color="black"
                  >mdi-upload</v-icon
                > -->
              <!-- <br />
              <br />
              <span
                style="padding-bottom: 6px; border-bottom: 2px solid grey"
                v-if="cvFile"
                >{{ cvFile.name }}
                <v-icon color="red" small @click="cvFile = null"
                  >mdi-close</v-icon
                ></span
              > -->
            </v-flex>

            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 text-left>
                  <span class="con4"
                    >Views on Wildlife Conservation in India*</span
                  >
                </v-flex>
                <v-flex xs12 py-2>
                  <v-textarea
                    class="tes"
                    v-model="contact.viewOnConservation"
                    :rules="reqRules"
                    required
                    outlined
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 align-self-center>
              <v-spacer></v-spacer>
            </v-flex>

            <v-flex xs12 align-self-end text-right>
              <v-btn
                @click="validate"
                :disabled="!valid"
                block
                dark
                :ripple="false"
                class="apply"
                x-large
              >
                <span>SUBMIT</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["jobTitle", "jobCategory"],
  data() {
    return {
      appLoading: false,
      flag: false,
      valid: true,
      ServerError: false,
      timeout: 5000,
      contact: {
        name: "",
        viewOnConservation: "",
        mobile: "",
        email: "",
        jobCategory: null,
        volunteerTitle: null,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      reqRules: [
        (v) => !!v || "Field is required",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length == 10 || "Invalid Phone Number",
      ],
      interests: [],
      showSnackBar: false,
      timeOut: 5000,
      msg: null,

      formDataCv: new FormData(),
      cvFile: null,
      coverFile: null,
    };
  },
  // watch: {
  //   jobTitle() {
  //     this.contact.jobTitle = this.jobTitle;
  //   },
  //   jobCategory() {
  //     this.contact.jobCategory = this.jobCategory;
  //   },
  // },
  beforeMount() {
    if (this.jobTitle) this.contact.volunteerTitle = this.jobTitle;

    if (this.jobCategory) this.contact.jobCategory = this.jobCategory;
  },
  methods: {
    browseImage(event) {
      var got = event.target.files[0];

      if (got.type == "application/pdf") {
        this.cvFile = got;
      } else {
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        var ty = event.target.files[0];
        console.log(ty);
        if (ty.size > 3145728) {
          document.getElementById('file2').value = []
          this.msg = "File size Should be less than 3MB";
          this.showSnackBar = true;
          return;
        } else {
          this.cvFile = got;

          // img.onload = () => {
          //   this.cvFile = URL.createObjectURL(event.target.files[0]);
          // };
          // console.log(img);
        }
      }
    },
    browseCover(event) {
      var got = event.target.files[0];

      if (got.type == "application/pdf") {
        this.coverFile = got;
      } else {
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        var ty = event.target.files[0];
        console.log(ty);
        if (ty.size > 3145728) {
          document.getElementById('file1').value = []
          this.msg = "File size Should be less than 3MB";
          this.showSnackBar = true;
          return;
        } else {
          this.coverFile = got;

          // img.onload = () => {
          //   this.cvFile = URL.createObjectURL(event.target.files[0]);
          // };
          // console.log(img);
        }
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.cvFile) {
          this.addContact();
        }
        else {
          this.msg="Please upload your Resume!"
          this.showSnackBar=true
        }
      }
    },
    addContact() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/volunteer/application/apply",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            let id = response.data.data._id;
            if (this.cvFile && id) {
              this.uploadResume(id);
            }
            if (this.coverFile && id) {
              this.uploadCover(id);
            }
            // Object.keys(this.contact).forEach(
            //   (key) => (this.contact[key] = null)
            // );
            this.flag = true;
          } else {
            this.appLoading =false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
          this.clearOff();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadResume(item) {
      var formD = new FormData();
      this.appLoading = true;
      formD.append("id", item);
      formD.append("doc", this.cvFile);
      axios({
        method: "POST",
        url: "/volunteer/application/resume",
        data: formD,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showSnackBar = true;
            formD = new FormData();
            this.cvFile = null;
            this.$refs.form.resetValidation();
            // this.$router.go();
          } else {
            formD = new FormData();
            this.msg = "Can't Upload CV.. Please Try Again Later";
            this.showSnackBar = true;
          }
          document.getElementById('file2').value = []
          
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Uncaught!Can't Upload CV.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    uploadCover(item) {
      var formD = new FormData();
      this.appLoading = true;
      formD.append("id", item);
      formD.append("doc", this.coverFile);
      axios({
        method: "POST",
        url: "/volunteer/application/coverLetter",
        data: formD,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showSnackBar = true;
            formD = new FormData();
            this.coverFile = null;
            this.$refs.form.resetValidation();
            // this.$router.go();
          } else {
            formD = new FormData();
            this.msg = "Can't Upload CV.. Please Try Again Later";
            this.showSnackBar = true;
          }
          document.getElementById('file1').value = []
          
          })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Uncaught!Can't Upload CV.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    clearOff() {
      this.contact.name = "";
      this.contact.viewOnConservation = "";
      this.contact.mobile = "";
      this.contact.email = "";
    },
  },
};
</script>
<style scoped>
.apply {
  background-color: #5dc269 !important;
}
.apply:hover {
  background-color: #ecb338 !important;
}
</style>
